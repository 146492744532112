import { Box, Divider, Grid } from "@mui/material";

export const SafetyPage = () => {
    return (
        <Grid container justifyContent={'center'} flexDirection={'column'} paddingY={5}>
            <Box style={{
                padding: 20,
                backgroundColor: '#d7b8d7',
                flexDirection: 'column',
                textAlign: 'center',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}>
                <p style={{ fontWeight: '600', fontSize: 22 }}>Scrapbook's Commitment to Safety and Protection</p>
                <Grid item md={6} paddingX={5} paddingBottom={5}>
                    <Box>
                        <p style={{ color: '#4b4b4b' }}>
                            At Scrapbook, the safety and well-being of our community are our highest priorities.
                            We have a zero-tolerance policy toward child sexual abuse and exploitation (CSAE) and are dedicated to maintaining a secure platform for all users.
                        </p>
                    </Box>
                </Grid>
            </Box>

            <Box style={{
                padding: 20,
                flexDirection: 'column',
                textAlign: 'center',
                display: 'flex',
                flex: 1,
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center'
            }}>
                <Grid item md={6} paddingX={5} paddingBottom={5}>
                    <Box marginBottom={5}>
                        <b>Preventative Measures</b>
                        <ul style={{ textAlign: 'left', marginTop: 15 }}>
                            <li>Advanced content moderation tools to detect and prevent harmful or inappropriate content.</li>
                            <li>Clear guidelines prohibiting any content related to CSAE.</li>
                            <li>Collaborations with trusted organizations and law enforcement agencies to address violations swiftly.</li>
                            <li>Age verification and user safeguards to maintain a safe environment.</li>
                        </ul>
                    </Box>

                    <Divider />

                    <Box marginTop={5} marginBottom={5}>
                        <b>Reporting Mechanisms</b>
                        <p style={{ marginTop: 15 }}>
                            Scrapbook provides simple and effective reporting tools for users to flag any inappropriate content or behavior:
                        </p>
                        <ul style={{ textAlign: 'left', marginTop: 15 }}>
                            <li>In-app reporting features that are easy to access and use.</li>
                            <li>Direct support channels for reporting sensitive issues.</li>
                            <li>All reports are reviewed promptly, with immediate action taken to remove harmful content and notify relevant authorities.</li>
                        </ul>
                    </Box>

                    <Divider />

                    <Box marginTop={5} marginBottom={5}>
                        <b>Transparency and Accountability</b>
                        <p style={{ marginTop: 15 }}>
                            We are committed to being open about our efforts to protect our users. Scrapbook publishes periodic
                            transparency reports that provide insights into:
                        </p>
                        <ul style={{ textAlign: 'left', marginTop: 15 }}>
                            <li>The number of CSAE-related reports received and addressed.</li>
                            <li>Actions taken to improve platform safety, including policy updates and system enhancements.</li>
                        </ul>
                    </Box>

                    <Divider />

                    <Box marginTop={5}>
                        <b>Commitment to Continuous Improvement</b>
                        <p style={{ marginTop: 15 }}>
                            Scrapbook is dedicated to evolving our policies and practices to meet emerging challenges.
                            We collaborate with global experts and invest in cutting-edge technologies to ensure the safety
                            and privacy of our users at all times.
                        </p>
                    </Box>
                </Grid>
            </Box>
        </Grid>
    );
};
