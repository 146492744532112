import { PageContent } from "./PageContent";
import { Grid, TextField, Typography } from "@mui/material";
import { Button } from "../components/Buttons/Button";
import { DatePicker } from "@mui/x-date-pickers";
import { useContext, useState } from "react";
import { addDateFetch } from "../fetch/dates";
import { useNavigate, useParams } from "react-router-dom";
import { AuthorizationContext } from "../context/AuthorizationProvider";
import { v4 as uuidv4 } from 'uuid';

export const AddDatePage = () => {
    const {id: relationshipId} = useParams<{id: string}>();
    const {token, profile} = useContext(AuthorizationContext);
    const [title, setTitle] = useState<string>('');
    const [location, setLocation] = useState<string>('');
    const [date, setDate] = useState<Date | null>(new Date());
    const navigate = useNavigate();

    const saveDate = async () => {
        if (relationshipId) {
            await addDateFetch(relationshipId, {
                date: date?.toISOString() ?? null,
                location: location,
                userId: profile?.email,
                relationshipId: relationshipId,
                id: uuidv4(),
                additionalInfo: {},
                rating: 0,
                title: title,
                partnerId: profile?.email
            }, token);
            navigate(`/partner/${relationshipId}`);
        }
    }

    return (
        <PageContent centered={true}>
            <Grid container spacing={4} direction={'column'} maxWidth={450} display={'flex'} flex={1} alignSelf={'center'}>
                <Grid item>
                    <Typography textAlign={'center'} variant={'h4'}>Add a Date</Typography>
                </Grid>
                <Grid item>
                    <TextField style={{ width: '100%', backgroundColor: 'white', borderRadius: 4 }}
                               label="Title"
                               variant="outlined"
                               color={'secondary'}
                               value={title}
                               onChange={(val) => {
                                      setTitle(val.target.value)
                               }} />
                </Grid>
                <Grid item>
                    <TextField style={{ width: '100%', backgroundColor: 'white', borderRadius: 4 }}
                               label="Location"
                               variant="outlined"
                               color={'secondary'}
                               value={location}
                               onChange={(val) => {
                                    setLocation(val.target.value)
                               }} />
                </Grid>
                <Grid item>
                    <div style={{backgroundColor: 'white'}}>
                        <DatePicker
                            className={'date-picker'}
                            onChange={(val) => {
                                if (val === null) return;
                                setDate(val.toDate())
                            }} />
                    </div>
                </Grid>
                <Grid item alignSelf={'center'} width={'100%'} display={'flex'} justifyContent={'center'}>
                    <Button onClick={saveDate}>Save</Button>
                </Grid>
            </Grid>
        </PageContent>
    )
}
